html, body {
  margin: 0;
  padding: 0;
  outline: 0;
  display: block;
  font-family: 'Roboto Thin', 'Roboto', sans-serif;
  font-size: 17px;
  background-color: #f6f6f6;
  color: #515151;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-text-stroke: 1px transparent;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-overflow-scrolling: touch;
  /*
  -------------------------------------------------------------------------------
  #FONT & TYPOGRAPHY
  -------------------------------------------------------------------------------
  */ }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
